import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { Router } from '@angular/router';
import { SnackBarService } from 'app/main/common/snackbar.service';
import { PermissionService } from '../common/permissions';
import { AdminService } from '../common/adminService';

import * as firebase from 'firebase';
import { MatDialog } from '@angular/material';
import { OtpVerifyComponent } from '../otp-verify/otp-verify.component';


@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    sessionData: any;
    password = '.{8,}';
    loading: boolean = false;
    displayMenuItem = [];
    allFalse: boolean;
    recaptchaVerifier: firebase.auth.RecaptchaVerifier;
    confirmationResult: firebase.auth.ConfirmationResult;
    dialogRef: any;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private cookieService: CookieService,
        private adminService: AdminService,
        private router: Router,
        private snackBarService: SnackBarService,
        private permissionService: PermissionService,
        private _matDialog: MatDialog,


    ) {
        console.log(this.router.url);

        // Configure the layout
        this._fuseConfigService.config = {
            colorTheme: 'theme-default',
            layout: {
                style: 'vertical-layout-1',
                width: 'fullwidth',
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    ngOnInit(): void {

        if ((this.cookieService.get('sEmail') !== '') && (this.cookieService.get('password') !== '')) {
            this.loginForm['sEmail'] = this.cookieService.get('sEmail');
            this.loginForm['sPassword'] = this.cookieService.get('sPassword');
            // this.loginForm['bRememberMe'] = true;
            // this.loginForm.eUserType = 'admin';
        }

        this.loginForm = this._formBuilder.group({
            sEmail: ['', [Validators.required]],
            sPassword: ['', [Validators.required]],
            // sMobile: ['', [Validators.required]]
        });

        this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button');

        // this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
        //     'size': 'invisible'
        //     });
    }

    login() {
        this.loading = true;
        if (this.router.url == '/investigator') {
            this.adminService.investigatorlogin(this.loginForm.value)
                .subscribe(res => {
                    if (res['data'].sPhoneNumber) {
                        firebase.auth().signInWithPhoneNumber('+' + res['data'].sPhoneNumber, this.recaptchaVerifier)
                            .then((confirmationResult) => {
                                // SMS sent. Prompt user to type the code from the message, then sign the
                                // user in with confirmationResult.confirm(code).
                                this.confirmationResult = confirmationResult;
                                this.dialogRef = this._matDialog.open(OtpVerifyComponent, {
                                    disableClose: false,
                                    data: {
                                        form: this.loginForm.value,
                                        confirmresult: this.confirmationResult
                                    }
                                });
                                this.dialogRef.afterClosed().subscribe((response) => {
                                    this.loading = false;
                                    if (response && response.success) {
                                        this.cookieService.set('sEmail', this.loginForm.value.sEmail);
                                        this.cookieService.set('sPassword', this.loginForm.value.sPassword);
                                        if (res) {
                                            this.loading = false;
                                            const response = res;
                                            this.sessionData = {
                                                'Authorization': response['data'].Authorization
                                            };
                                            this.adminService.storageValues = this.sessionData;
                                            localStorage.setItem('currentUser', JSON.stringify(this.sessionData));
                                            localStorage.setItem('UserType', 'investigator');
                                        }
                                        this.router.navigate(['dashboard']);
                                    }
                                });

                            }).catch(err => {
                                this.loading = false;
                                this.snackBarService.snackbarMesssage(err.message, 'Cancel');
                            });
                    } else {
                        if (res) {
                            this.loading = false;
                            const response = res;
                            this.sessionData = {
                                'Authorization': response['data'].Authorization
                            };
                            localStorage.setItem('currentUser', JSON.stringify(this.sessionData));
                            localStorage.setItem('UserType', 'investigator');
                        }
                        // this.router.navigate(['/dashboard']);
                        this.router.navigate(['/dashboard']);
                    }
                    // if (res) {
                    //     this.loading = false;
                    //     const response = res;
                    //     this.sessionData = {
                    //         'Authorization': response['data'].Authorization
                    //     };
                    //     localStorage.setItem('currentUser', JSON.stringify(this.sessionData));
                    //     localStorage.setItem('UserType', 'investigator');

                    // }
                },
                    error => {
                        console.log(error);

                        this.loading = false;
                        this.snackBarService.snackbarMesssage(error.error.message, 'Cancel');
                        this.router.navigate(['/']);

                    }
                );
        } else {

            this.adminService.login(this.loginForm.value)
                .subscribe(res => {
                    console.log(res['data'].sPhoneNumber);
                    if (res['data'].sPhoneNumber) {
                        firebase.auth().signInWithPhoneNumber('+' + res['data'].sPhoneNumber, this.recaptchaVerifier)
                            .then((confirmationResult) => {
                                // SMS sent. Prompt user to type the code from the message, then sign the
                                // user in with confirmationResult.confirm(code).
                                this.confirmationResult = confirmationResult;
                                this.loading = false;
                                this.cookieService.set('sEmail', this.loginForm.value.sEmail);
                                this.cookieService.set('sPassword', this.loginForm.value.sPassword);

                                this.dialogRef = this._matDialog.open(OtpVerifyComponent, {
                                    disableClose: false,
                                    data: {
                                        form: this.loginForm.value,
                                        confirmresult: this.confirmationResult
                                    }
                                });
                                this.dialogRef.afterClosed().subscribe((response) => {
                                    
                                    this.loading = false;

                                    if (response && response.success) {
                                        if (res) {
                                            this.loading = false;
                                            const response = res;
                                            this.sessionData = {
                                                'Authorization': response['data'].Authorization
                                            };
                                            this.adminService.storageValues = this.sessionData;
                                            localStorage.setItem('currentUser', JSON.stringify(this.sessionData));
                                            localStorage.setItem('UserType', 'admin');
                                        }
                                        this.router.navigate(['/dashboard']);
                                    }
                                });

                            }).catch(err => {
                                this.loading = false;
                                this.snackBarService.snackbarMesssage(err.message, 'Cancel');
                            });
                    } else {
                        if (res) {
                            this.loading = false;
                            const response = res;
                            this.sessionData = {
                                'Authorization': response['data'].Authorization
                            };
                            localStorage.setItem('currentUser', JSON.stringify(this.sessionData));
                            localStorage.setItem('UserType', 'admin');
                        }
                        this.router.navigate(['/dashboard']);
                    }
                    res['data'].aPermissions.map(singlePermissions => {
                        let false_flag = false;
                        singlePermissions.operations.map(singleOperation => {
                            if (singleOperation.canOperate === "true") {
                                false_flag = true;
                            }
                        });
                        if (!false_flag) {
                            if (this.displayMenuItem.indexOf(singlePermissions.permissions) === -1) {
                                this.displayMenuItem.push(singlePermissions.permissions);
                            }
                        }
                    });
                    this.permissionService.showHideMenuItem(this.displayMenuItem);
                    // this.router.navigate(['/otp-verification']);
                    // this.router.navigate(['/dashboard']);
                },
                    error => {
                        this.loading = false;
                        this.snackBarService.snackbarMesssage(error.error.message, 'Cancel');
                        this.router.navigate(['/']);

                    }
                );
        }
    }
}
