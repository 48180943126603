import { Component, OnDestroy, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { Subject, Subscription, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { navigation } from 'app/navigation/navigation';
import { Router } from '@angular/router';
import { AdminService } from 'app/main/common/adminService';
import { SnackBarService } from 'app/main/common/snackbar.service';
import { environment } from 'environments/environment';
import { PermissionService } from 'app/main/common/permissions';

@Component({
	selector: 'toolbar',
	templateUrl: './toolbar.component.html',
	styleUrls: ['./toolbar.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent implements OnInit, OnDestroy {
	horizontalNavbar: boolean;
	rightNavbar: boolean;
	hiddenNavbar: boolean;
	languages: any;
	navigation: any;
	selectedLanguage: any;
	userStatusOptions: any[];
	subscription: Subscription;
	profilePic: string;

	// Private
	private _unsubscribeAll: Subject<any>;
	sAdminName: any;
	getSubject: any;
	currentUser$: Observable<any>;
	currentUserProfilePic: string;
	usertype: string;

	/**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
	constructor(
		private _fuseConfigService: FuseConfigService,
		private _fuseSidebarService: FuseSidebarService,
		private _translateService: TranslateService,
		private router: Router,
		private adminService: AdminService,
		private snackbarService: SnackBarService,
		private permissionService: PermissionService
	) {
		this.usertype = localStorage.getItem('UserType');
		if (this.adminService.isAuthorized()) {
			let token = this.adminService.getAuthToken();
			if(this.usertype == 'admin'){
			this.currentUser$ = this.adminService.getprofiledataToken(token);
			this.currentUser$.subscribe((data) => {
				if (data.data.sProfilePicture) {
					this.profilePic = environment.profileUrl + data.data.sProfilePicture;
				} else {
					this.profilePic = 'assets/images/avatars/profile.jpg';
				}
				this.sAdminName = data.data.sFirstName + ' ' + data.data.sLastName;
			});
		}
	}
		this.navigation = navigation;

		/** For dynemically change Profile picture in header */
		this.subscription = this.adminService.getProfilePic().subscribe((profilePic) => {
			this.profilePic = profilePic.text;
		});

		/** For dynemically change admin name in header */
		this.subscription = this.adminService.getAdminName().subscribe((adminName) => {
			this.sAdminName = adminName.name;
		});

		// Set the private defaults
		this._unsubscribeAll = new Subject();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
     * On init
     */
	ngOnInit(): void {
		this.usertype = localStorage.getItem('UserType');
		// Subscribe to the config changes
		this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((settings) => {
			this.horizontalNavbar = settings.layout.navbar.position === 'top';
			this.rightNavbar = settings.layout.navbar.position === 'right';
			this.hiddenNavbar = settings.layout.navbar.hidden === true;
		});

		/* Set the selected language from default languages */
		this.selectedLanguage = _.find(this.languages, { id: this._translateService.currentLang });

		// this.adminService.getprofiledata().subscribe((data) => {
		// 	if (data.data.sProfilePicture) {
		// 		this.profilePic = environment.profileUrl + data.data.sProfilePicture;
		// 	} else {
		// 		this.profilePic = 'assets/images/avatars/profile.jpg';
		// 	}
		// 	this.sAdminName = data.data.sFirstName + ' ' + data.data.sLastName;
		// });
	}

	/**
     * On destroy
     */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
     * Toggle sidebar open
     *
     * @param key
     */
	toggleSidebarOpen(key): void {
		this._fuseSidebarService.getSidebar(key).toggleOpen();
	}

	/**
     * Search
     *
     * @param value
     */
	search(value): void {
		// Do your search here...
		console.log(value);
	}

	profile() {
		this.router.navigate(['/profile']);
	}

	changePassword() {
		this.router.navigate(['/change-password']);
	}

	/**
     * Logout Api Call
     *
     */
	logout() {
		if (this.usertype == 'admin') {
			this.adminService.logout().subscribe(
				(res) => {
					let resMessage = res['message'];
					localStorage.removeItem('currentUser');
					localStorage.removeItem('profilePic');
					localStorage.removeItem('UserType');
					// this.permissionService.showHideMenuItem
					this.snackbarService.snackbarMesssage(resMessage, 'Cancel');
					this.router.navigate(['/']);
				},
				(error) => {
					if (error.status === 401 || error.status === 406) {
						this.router.navigate(['/']);
					} else {
						this.snackbarService.snackbarMesssage(error.message, 'Cancel');
					}
				}
			);
		} else {
			this.adminService.investigatorlogout().subscribe(
				(res) => {
					let resMessage = res['message'];
					localStorage.removeItem('currentUser');
					localStorage.removeItem('profilePic');
					localStorage.removeItem('UserType');
					// this.permissionService.showHideMenuItem
					this.snackbarService.snackbarMesssage(resMessage, 'Cancel');
					this.router.navigate(['/investigator']);
				},
				(error) => {
					if (error.status === 401 || error.status === 406) {
						this.router.navigate(['/investigator']);
					} else {
						this.snackbarService.snackbarMesssage(error.message, 'Cancel');
					}
				}
			);
		}
	}
}
