'use strict';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import { map } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { PermissionService } from './permissions';

@Injectable()
export class AdminService {
    private currentUserSubject: BehaviorSubject<any>;
    private subject = new Subject<any>();
    private subjectAdminName = new Subject<any>();
    private baseUrl: string = environment.apiUrl + environment.version;
    public currentUser: Observable<any>;
    // protected isLoggedin: boolean = false;
    protected isLoggedInCheck: boolean = false;
    public storageValues;
    token: string;

    constructor(
        private http: HttpClient,
        private router: Router,
    ) {
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        // this.isLoggedin = !!localStorage.getItem('currentUser');
        this.isLoggedInCheck = !!localStorage.getItem('currentUser');
    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    public getAuthToken() {
        //to check token is there in localstorage or not
        var storageValues = (!this.storageValues) ? JSON.parse(localStorage.getItem('currentUser')) : this.storageValues;
        if (storageValues != null) {
            return storageValues.Authorization;
        } else {
            return '';
        }
    }

    /** Function to naviggate */
    isLoggedIn(url: string): boolean {
        var storageValues = (!this.storageValues) ? JSON.parse(localStorage.getItem('currentUser')) : this.storageValues;
        if (storageValues !== null && storageValues !== '') {
            if (url === '/' || url === '/login') {
                this.router.navigate(['/dashboard']);
                return false;
            } else {
                return true;
            }
        } else {
            if (url !== '/') {
                this.router.navigate(['/']);
                return false;
            } else {
                return true;
            }
        }
    }

    /* General APIs */
    /**Login*/
    login(loginData) {

        return this.http.post(`${this.baseUrl}admin/login`, loginData).pipe(
            map((response) => {
                console.log("res", response);

                this.isLoggedInCheck = true;
                this.currentUserSubject.next(response['data']);
                return response;
            })
        );
    }

    investigatorlogin(data) {
        console.log("data", data);

        return this.http.post(`${this.baseUrl}investigator/login`, data).pipe(
            map((response) => {
                console.log("res", response);

                this.isLoggedInCheck = true;
                this.currentUserSubject.next(response['data']);
                return response;
            })
        );
    }

    /* logout */
    investigatorlogout() {
        const authToken = this.getAuthToken();
        if (authToken !== '') {
            return this.http.get(`${this.baseUrl}investigator/logout`).pipe(
                map((response: any) => {
                    this.isLoggedInCheck = !this.isLoggedInCheck;
                    this.currentUserSubject.next(null);
                    return response;
                })
            );
        }
    }
    logout() {
        const authToken = this.getAuthToken();
        if (authToken !== '') {
            return this.http.get(`${this.baseUrl}admin/logout`).pipe(
                map((response: any) => {
                    this.isLoggedInCheck = !this.isLoggedInCheck;
                    this.storageValues = null;
                    this.currentUserSubject.next(null);
                    return response;
                })
            );
        }
    }

    /** Function for Authentication */
    public isAuthorized() {
        if (!this.isLoggedInCheck) {
            return false;
        }
        return true;
    }

    /** Update Profile  */
    updateprofilepic(data) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.post<any>(`${this.baseUrl}admin/profilepicture`, data).pipe(
                map((response: Response) => {
                    this.subject.next({
                        text: environment.profileUrl + response['data']
                    });
                    return response;
                })
            );
        }
    }

    getinvestigatorbystudy(id) {
        return this.http.get(`${this.baseUrl}investigator/study/${id}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    investigatorformsubmit(data) {
        return this.http.post(`${this.baseUrl}investigator/register`, data).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    /** admin profile  */
    getprofiledata() {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.get(`${this.baseUrl}admin/profile`).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    /** Global Api call for get admin data  */
    getprofiledataToken(token) {
        if (this.getAuthToken()) return this.http.post(`${this.baseUrl}admin/commonuserdata`, { token: token });
    }

    /** update admin profile */
    updateAdminProfile(data) {
        return this.http.put(`${this.baseUrl}admin/profile`, data).pipe(
            map((response: any) => {
                this.subjectAdminName.next({
                    name: response['data'].sFirstName + ' ' + response['data'].sLastName
                });
                return response;
            })
        );
    }

    /** Registration */
    signup(registerData) {
        return this.http.post<any>(`${this.baseUrl}auth/register`, registerData).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    /** forgot password */
    forgotPassword(ForgotData) {
        return this.http.post<any>(`${this.baseUrl}admin/forgot/password/mail`, ForgotData).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    /** Reset password */
    resetpassword(token, sNewPassword, sNewRetypedPassword) {
        return this.http
            .post<any>(`${this.baseUrl}admin/reset/password`, {
                sVerificationToken: token,
                sNewPassword: sNewPassword,
                sNewRetypedPassword: sNewRetypedPassword
            })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    //** reset password Token verify*/
    tokenVerify(token) {
        return this.http.get<any>(`${this.baseUrl}admin/verifytoken/${token}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    /** Change Password */
    changePassword(password) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.post(`${this.baseUrl}admin/password/change`, password).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    changepasswordinvestigator(password) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.post(`${this.baseUrl}investigator/password/change`, password).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    /* get data */
    getProfilePic(): Observable<any> {
        return this.subject.asObservable();
    }
    /* get data */
    getAdminName(): Observable<any> {
        return this.subjectAdminName.asObservable();
    }

    /** SUBADMIN APIS CALL*/
    //** Sub Admin Token verify*/
    subAdminTokenVerify(token) {
        return this.http.get<any>(`${this.baseUrl}admin/subadmin/mail/verification/${token}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    /** get Permission Data*/
    getPermissionData() {
        return this.http.get<any>('../../../assets/SubAdmin_permissions.json').pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    /** Get Subadmin Details By Id*/
    getSubadminById(id: String) {
        const authToken = this.getAuthToken();
        if (authToken !== '') {
            return this.http.get(`${this.baseUrl}admin/subadmin/${id}`).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    /** Subadmin List */
    subAdminList(subAdmin) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.post(`${this.baseUrl}admin/subadmin/list`, subAdmin).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    /** Subadmin update  */
    updateSubAdmin(id, data) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http
                .put(`${this.baseUrl}admin/subadmin/${id}`, data, { headers: { Authorization: authToken } })
                .pipe(
                    map((response: any) => {
                        return response;
                    })
                );
        }
    }

    /** SubAdmin status update */
    subAdminStatusupdate(id, status) {
        let data = {
            iItemId: id,
            eIsActive: status
        };
        var authToken = this.getAuthToken();
        return this.http
            .post(`${this.baseUrl}admin/subadmin/changestatus`, data, { headers: { Authorization: authToken } })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /** Subadmin status update */
    addSubAdmin(subadminData) {
        var authToken = this.getAuthToken();
        return this.http
            .post(`${this.baseUrl}admin/subadmin`, subadminData, { headers: { Authorization: authToken } })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /** Delete Subadmin */
    deleteSubAdmin(id) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.delete(`${this.baseUrl}admin/subadmin/${id}`).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    /** SPONSER APIS CALL */
    /** Delete Sponser */
    deleteSponser(id) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.delete(`${this.baseUrl}admin/sponsor/${id}`).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    /** Sponser Management List */
    sponserList(sponser) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.post(`${this.baseUrl}admin/list/sponsor`, sponser).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    /** Sponser status update */
    sponserStatusupdate(id, status) {
        let data = {
            iItemId: id,
            bStatus: status
        };
        var authToken = this.getAuthToken();
        return this.http
            .post(`${this.baseUrl}admin/sponsor/changestatus`, data, { headers: { Authorization: authToken } })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /**	Sponser status update */
    addSponser(sponserData) {
        var authToken = this.getAuthToken();
        return this.http
            .post(`${this.baseUrl}admin/sponsor`, sponserData, { headers: { Authorization: authToken } })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /* Sponser View  */
    viewSponser(id) {
        if (this.getAuthToken()) {
            return this.http
                .get(`${this.baseUrl}admin/sponsor/${id}`, { headers: { Authorization: this.getAuthToken() } })
                .pipe(
                    map((response: any) => {
                        let data = {
                            _id: response.data._id,
                            sName: response.data.sName,
                            sSponsorImage: response.data.sSponsorImage,
                            sWeblink: response.data.sWeblink
                        };
                        return data;
                    })
                );
        }
    }

    /** sponser edit */
    editSponser(id, sponserData) {
        const authToken = this.getAuthToken();
        if (authToken !== '') {
            return this.http.put<any>(`${this.baseUrl}admin/sponsor/${id}`, sponserData).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    /** CMS APIS CALL */
    /** CMS List */
    CMSList(CMS) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.post(`${this.baseUrl}admin/list/cms`, CMS).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    /* CMS View  */
    viewCMS(id) {
        if (this.getAuthToken()) {
            return this.http
                .get(`${this.baseUrl}admin/cms/${id}`, { headers: { Authorization: this.getAuthToken() } })
                .pipe(
                    map((response: any) => {
                        let data = {
                            _id: response.data._id,
                            sDesc: response.data.sDesc,
                            sPageName: response.data.sPageName
                        };
                        return data;
                    })
                );
        }
    }

    /* cms update  */
    updateCMS(id, data) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http
                .put(`${this.baseUrl}admin/cms/${id}`, data, { headers: { Authorization: authToken } })
                .pipe(
                    map((response: any) => {
                        return response;
                    })
                );
        }
    }

        /** 
         * Settings api
        */
        /* Setting View  */
        viewSetting() {
            if (this.getAuthToken()) {
                return this.http
                    .get(`${this.baseUrl}admin/searchcreiteria`, { headers: { Authorization: this.getAuthToken() } })
                    .pipe(
                        map((response: any) => {
                            let data = {
                                _id: response.data._id,
                                sSearchBy: response.data.sSearchBy,
                            };
                            return data;
                        })
                    );
            }
        }

        /* Setting update  */
        updateSetting( data) {
            var authToken = this.getAuthToken();
            if (authToken != '') {
                return this.http
                    .post(`${this.baseUrl}admin/searchcreiteria`, data, { headers: { Authorization: authToken } })
                    .pipe(
                        map((response: any) => {
                            return response;
                        })
                    );
            }
        }


    /** CATEGORY APIS CALL */
    /** Category List */
    categoryList() {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.get(`${this.baseUrl}admin/category/tree/view`).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    categorySimpleList() {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.get(`${this.baseUrl}admin/category/list`).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    categorySimpleListFilter(category) {
        let data = {
            search: category
        }
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.post(`${this.baseUrl}admin/list/category`, data).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    /**	Category Add */
    addCategory(category) {
        var authToken = this.getAuthToken();
        return this.http
            .post(`${this.baseUrl}admin/category`, { sTitle: category }, { headers: { Authorization: authToken } })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /** Sub-Category Add */
    addchildCategory(id, category) {
        var authToken = this.getAuthToken();
        return this.http
            .post(
                `${this.baseUrl}admin/category`,
                { iParentId: id, sTitle: category },
                { headers: { Authorization: authToken } }
            )
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /**	Category Select Country */
    selectCountry(search) {
        var authToken = this.getAuthToken();
        return this.http
            .post(
                `${this.baseUrl}admin/list/countries/dropdown`,
                { search: search },
                { headers: { Authorization: authToken } }
            )
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    selectCountryNoAuth(search) {
        return this.http
            .post(
                `${this.baseUrl}investigator/list/countries/dropdown`,
                { search: search }
            )
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /**	Category Select Country */
    selectCity(countryId, search) {
        var authToken = this.getAuthToken();
        return this.http
            .post(
                `${this.baseUrl}admin/city/list/dropdown/${countryId}`,
                { search: search },
                { headers: { Authorization: authToken } }
            )
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    selectCityNoAuth(countryId, search) {
        return this.http
            .post(
                `${this.baseUrl}investigator/city/list/dropdown/${countryId}`,
                { search: search }
            )
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /**	Category Select Country */
    selectHospitalWithCityid(cityId) {
        var authToken = this.getAuthToken();
        return this.http
            .get(
                `${this.baseUrl}admin/simpleList/hospital/${cityId}`,
                { headers: { Authorization: authToken } }
            )
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    selectHospitalWithCityidNoAuth(cityId) {
        return this.http
            .get(
                `${this.baseUrl}investigator/list/hospital/${cityId}`
            )
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /**	Category Text Content Add */
    addTextContent(id, desc) {
        var authToken = this.getAuthToken();
        return this.http
            .post(
                `${this.baseUrl}admin/categorycontent`,
                { iItemId: id, sDesc: desc },
                { headers: { Authorization: authToken } }
            )
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /**	Category Accordion Table Add */
    addAccordionTable(id, AddItemArray) {
        var authToken = this.getAuthToken();
        return this.http
            .post(
                `${this.baseUrl}admin/category/collapse_table`,
                { iItemId: id, aCollapse_table: AddItemArray },
                { headers: { Authorization: authToken } }
            )
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /**	Category Calculator Add */
    addCalculator(categoryId, calculatorData) {
        var authToken = this.getAuthToken();
        return this.http
            .post(
                `${this.baseUrl}admin/category/calculator`,
                { iCategoryId: categoryId, sTitle: calculatorData.sTitle, aOptions: calculatorData.aOptions },
                { headers: { Authorization: authToken } }
            )
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /**	Category Guideline Add */
    addGuidelines(categoryId, guidelineData) {
        var authToken = this.getAuthToken();
        return this.http
            .post(
                `${this.baseUrl}admin/category/guideline`,
                {
                    iCategoryId: categoryId,
                    sTitle: guidelineData.sTitle,
                    bIsFirst: guidelineData.bIsFirst,
                    aOptions: guidelineData.aOptions
                },
                { headers: { Authorization: authToken } }
            )
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /**	Category Criteria Add */
    addCriteria(iCriteriaId, guidelineData) {
        var authToken = this.getAuthToken();
        return this.http
            .post(
                `${this.baseUrl}admin/criteria/content`,
                {
                    iCriteriaId: iCriteriaId,
                    sQuestion: guidelineData.sQuestion,
                    bIsFirst: guidelineData.bIsFirst,
                    aOptions: guidelineData.aOptions
                },
                { headers: { Authorization: authToken } }
            )
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /**	Category Explanation Add */
    addExplanationApi(categoryId, explanationData) {
        var authToken = this.getAuthToken();
        return this.http
            .post(
                `${this.baseUrl}admin/category/explanation`,
                {
                    iCategoryId: categoryId,
                    eScoreType: explanationData.eScoreType,
                    nScoreValueFrom: explanationData.nScoreValueFrom,
                    nScoreValueTo: explanationData.nScoreValueTo,
                    nScoreValue: explanationData.nScoreValue,
                    aLabels: explanationData.aLabels,
                    sLink: explanationData.sLink,
                    sText: explanationData.sText,
                    sDescription: explanationData.sDescription,
                    // aLinks: explanationData.aLinks
                },
                { headers: { Authorization: authToken } }
            )
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /**	Get Category Country List*/
    getCountryList() {
        var authToken = this.getAuthToken();
        return this.http
            .get(`${this.baseUrl}admin/list/countries/dropdown`, { headers: { Authorization: authToken } })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    // copy paste data
    copypastedata(body) {
        var authToken = this.getAuthToken();
        return this.http
            .post(`${this.baseUrl}admin/category/copypast`, body,
                { headers: { Authorization: authToken } })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /**	Get City Country List*/
    getSimpleCityList() {
        var authToken = this.getAuthToken();
        return this.http
            .get(`${this.baseUrl}admin/city/list/dropdown`, { headers: { Authorization: authToken } })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /**	Get City Country List*/
    getSelectedCountryCityList(countryId) {
        var authToken = this.getAuthToken();
        return this.http
            .post(`${this.baseUrl}admin/city/list/dropdown/${countryId}`, { headers: { Authorization: authToken } })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /** Get Category Content */
    getContent(id) {
        var authToken = this.getAuthToken();
        return this.http.get(`${this.baseUrl}admin/category/${id}`, { headers: { Authorization: authToken } }).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    /** Get Criteria Content */
    getCriteriaContent(id) {
        var authToken = this.getAuthToken();
        return this.http.get(`${this.baseUrl}admin/criteria/${id}`, { headers: { Authorization: authToken } }).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    /** Get Explanation */
    getExplanation(id) {
        var authToken = this.getAuthToken();
        return this.http
            .get(`${this.baseUrl}admin/category/explanation/${id}`, { headers: { Authorization: authToken } })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /** Get Accordion Item */
    getAccordionItem(categoryId, itemId) {
        var authToken = this.getAuthToken();
        return this.http
            .get(`${this.baseUrl}admin/category/collapse_table/${categoryId}/${itemId}`, {
                headers: { Authorization: authToken }
            })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /** Get Accordion Item */
    getTextContent(id) {
        var authToken = this.getAuthToken();
        return this.http
            .get(`${this.baseUrl}admin/category/content/${id}`, { headers: { Authorization: authToken } })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /** Get Category Content */
    viewCategoryContent(id) {
        var authToken = this.getAuthToken();
        return this.http
            .get(`${this.baseUrl}admin/category/selectedcontent/${id}`, { headers: { Authorization: authToken } })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }


    /** Category update  */
    updateCategory(id, categoryName, parentId) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            let requestData = {}
            if (parentId !== '') {
                requestData = { sTitle: categoryName, iParentId: parentId }
            } else {
                requestData = { sTitle: categoryName }
            }
            return this.http
                .put(
                    `${this.baseUrl}admin/category/${id}`,
                    requestData,
                    { headers: { Authorization: authToken } }
                )
                .pipe(
                    map((response: any) => {
                        return response;
                    })
                );
        }
    }

    /** Update Accordion Item */
    updateAccordionItem(categoryId, itemId, updateData) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http
                .put(
                    `${this.baseUrl}admin/category/collapse_table/${categoryId}`,
                    { iItemId: itemId, data: updateData },
                    { headers: { Authorization: authToken } }
                )
                .pipe(
                    map((response: any) => {
                        return response;
                    })
                );
        }
    }

    /** Update Calculator Item */
    updateCalculatorItemApi(itemId, updateData) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http
                .put(
                    `${this.baseUrl}admin/category/calculator/${itemId}`,
                    { sTitle: updateData.sTitle, aOptions: updateData.aOptions },
                    { headers: { Authorization: authToken } }
                )
                .pipe(
                    map((response: any) => {
                        return response;
                    })
                );
        }
    }

    /** Update Guideline Item */
    updateGuidelineItemApi(itemId, updateData) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http
                .put(
                    `${this.baseUrl}admin/category/guideline/${itemId}`,
                    { sTitle: updateData.sTitle, bIsFirst: updateData.bIsFirst, aOptions: updateData.aOptions,iCategoryId:updateData.iCategoryId },
                    { headers: { Authorization: authToken } }
                )
                .pipe(
                    map((response: any) => {
                        return response;
                    })
                );
        }
    }

    /** Update Criteria Item */
    updateCriteriaItemApi(itemId, updateData) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http
                .put(
                    `${this.baseUrl}admin/criteria/content/${itemId}`,
                    { sQuestion: updateData.sQuestion, bIsFirst: updateData.bIsFirst, aOptions: updateData.aOptions },
                    { headers: { Authorization: authToken } }
                )
                .pipe(
                    map((response: any) => {
                        return response;
                    })
                );
        }
    }

    /** Update Explanation Item */
    updateExplanationApi(categoryId, expId, explanationData) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http
                .put(
                    `${this.baseUrl}admin/category/explanation/${expId}`,
                    {
                        iCategoryId: categoryId,
                        eScoreType: explanationData.eScoreType,
                        nScoreValueFrom: explanationData.nScoreValueFrom,
                        nScoreValueTo: explanationData.nScoreValueTo,
                        nScoreValue: explanationData.nScoreValue,
                        aLabels: explanationData.aLabels,
                        sLink: explanationData.sLink,
                        sText: explanationData.sText,
                        sDescription: explanationData.sDescription,
                        // aLinks: explanationData.aLinks
                    },
                    { headers: { Authorization: authToken } }
                )
                .pipe(
                    map((response: any) => {
                        return response;
                    })
                );
        }
    }

    /** Country update  */
    updateCountry(data) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http
                .post(
                    `${this.baseUrl}admin/category/country`,
                    { iItemId: data.id, aCountries: data.aCountries },
                    { headers: { Authorization: authToken } }
                )
                .pipe(
                    map((response: any) => {
                        return response;
                    })
                );
        }
    }

    /** Category status update */
    categoryStatusupdate(id, status) {
        let data = {
            iItemId: id,
            bStatus: status
        };
        var authToken = this.getAuthToken();
        return this.http.put(`${this.baseUrl}admin/category`, data, { headers: { Authorization: authToken } }).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    /**	Add Graphical Content */
    addGraphicalData(id, image) {
        var authToken = this.getAuthToken();
        return this.http
            .post(`${this.baseUrl}admin/categoryimage/${id}`, image, { headers: { Authorization: authToken } })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /** sponser edit */
    editGraphicalData(id, image) {
        const authToken = this.getAuthToken();
        if (authToken !== '') {
            return this.http.post<any>(`${this.baseUrl}admin/updatecategoryimage/${id}`, image).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    pdfview(id) {
        const authToken = this.getAuthToken();
        if (authToken !== '') {
            return this.http.get<any>(`${this.baseUrl}admin/category/selectedcontent/${id}`).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }
    /**	Category PDF Add */
    addPdfApi(id, pdf) {
        var authToken = this.getAuthToken();
        return this.http
            .post(`${this.baseUrl}admin/categorypdf/${id}`, pdf, { headers: { Authorization: authToken } })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    editPdfApi(id, pdf) {
        var authToken = this.getAuthToken();
        return this.http
            .put(`${this.baseUrl}admin/categorypdf/${id}`, pdf, { headers: { Authorization: authToken } })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /** Delete Category with there Subcategory */
    deleteCategory(id) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.delete(`${this.baseUrl}admin/category/${id}`).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    /** Delete Accordion Item */
    deleteAccordionItem(categoryId, itemId) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.delete(`${this.baseUrl}admin/category/collapse_table/${categoryId}/${itemId}`).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    /** Delete Calculator Item */
    deleteCalculatorItemApi(itemId) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.delete(`${this.baseUrl}admin/category/calculator/${itemId}`).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    /** Delete Calculator Item */
    deleteGuidelineItemApi(itemId) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.delete(`${this.baseUrl}admin/category/guideline/${itemId}`).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    /** Delete Explanation Item */
    deleteExplanationApi(explanationId) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.delete(`${this.baseUrl}admin/category/explanation/${explanationId}`).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    deleteExplanationByCategoryApi(payload) {
        var authToken = this.getAuthToken();
        if (authToken != '') {

            return this.http.post(`${this.baseUrl}admin/category/explanation/delete`, payload).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }
    /** Trial-Tool Apis*/

    /** Country Apis */
    /** Country List */
    CountryListApi(Country) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.post(`${this.baseUrl}admin/list/countries`, Country).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    CityListApi(Country) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.post(`${this.baseUrl}admin/city/list`, Country).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    /** Country status update */
    CountriesStatusupdate(id, status) {
        let data = {
            iItemId: id,
            bStatus: status
        };
        var authToken = this.getAuthToken();
        return this.http
            .post(`${this.baseUrl}admin/country/changestatus`, data, { headers: { Authorization: authToken } })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    CityStatusupdate(id, status) {
        let data = {
            iItemId: id,
            bStatus: status
        };
        var authToken = this.getAuthToken();
        return this.http
            .post(`${this.baseUrl}admin/city/changestatus`, data, { headers: { Authorization: authToken } })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /** Country Add */
    addCountriesTrialTool(country) {
        var authToken = this.getAuthToken();
        return this.http
            .post(`${this.baseUrl}admin/country`, country, { headers: { Authorization: authToken } })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    addCityTrialTool(country) {
        var authToken = this.getAuthToken();
        return this.http
            .post(`${this.baseUrl}admin/city`, country, { headers: { Authorization: authToken } })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /** Country Get */
    getCountryTrialTool(id) {
        var authToken = this.getAuthToken();
        return this.http.get(`${this.baseUrl}admin/country/${id}`, { headers: { Authorization: authToken } }).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    /** Update Country */
    updateCountryTrialTool(id, country) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http
                .put(
                    `${this.baseUrl}admin/country/${id}`,
                    country,
                    { headers: { Authorization: authToken } }
                )
                .pipe(
                    map((response: any) => {
                        return response;
                    })
                );
        }
    }
    updateCityTrialTool(id, country) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http
                .put(
                    `${this.baseUrl}admin/city/${id}`,
                    country,
                    { headers: { Authorization: authToken } }
                )
                .pipe(
                    map((response: any) => {
                        return response;
                    })
                );
        }
    }

    /** Delete Country */
    deleteCountryTrialTool(id) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.delete(`${this.baseUrl}admin/country/${id}`).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    deleteCityTrialTool(id) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.delete(`${this.baseUrl}admin/city/${id}`).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    /** Dieases Apis */
    /** List Dieases */
    DieasesListApi(Dieases) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.post(`${this.baseUrl}admin/list/disease`, Dieases).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    /** Disease status update */
    dieasesStatusupdate(id, status) {
        let data = {
            iItemId: id,
            bStatus: status
        };
        var authToken = this.getAuthToken();
        return this.http
            .post(`${this.baseUrl}admin/disease/changestatus`, data, { headers: { Authorization: authToken } })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /** Disease Add*/
    addDiseaseTrialTool(disease) {
        var authToken = this.getAuthToken();
        return this.http
            .post(`${this.baseUrl}admin/disease`, { sTitle: disease }, { headers: { Authorization: authToken } })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /**Get Disease */
    getDiseaseTrialTool(id) {
        var authToken = this.getAuthToken();
        return this.http.get(`${this.baseUrl}admin/disease/${id}`, { headers: { Authorization: authToken } }).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    /** Disease update */
    updateDiseaseTrialTool(id, disease) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http
                .put(
                    `${this.baseUrl}admin/disease/${id}`,
                    { sTitle: disease },
                    { headers: { Authorization: authToken } }
                )
                .pipe(
                    map((response: any) => {
                        return response;
                    })
                );
        }
    }

    /** Delete Disease */
    deleteDiseaseTrialTool(id) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.delete(`${this.baseUrl}admin/disease/${id}`).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    /** Study Status Apis */
    /** List Study Status */
    addStudyStatusTrialTool(studyStatus) {
        var authToken = this.getAuthToken();
        return this.http
            .post(`${this.baseUrl}admin/status`, studyStatus, { headers: { Authorization: authToken } })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /** Study Status status update */
    StudyStatusChangeStatusupdate(id, status) {
        let data = {
            iItemId: id,
            bStatus: status
        };
        var authToken = this.getAuthToken();
        return this.http
            .post(`${this.baseUrl}admin/status/changestatus`, data, { headers: { Authorization: authToken } })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /** Delete Study Status */
    deleteStudyStatusTrialTool(id) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.delete(`${this.baseUrl}admin/status/${id}`).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    /** Update Study Status */
    updateStudyStatusTrialTool(id, studyStatus) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http
                .put(
                    `${this.baseUrl}admin/status/${id}`,
                    studyStatus,
                    { headers: { Authorization: authToken } }
                )
                .pipe(
                    map((response: any) => {
                        return response;
                    })
                );
        }
    }

    /**Get Study Status */
    getStudyStatusTrialTool(id) {
        var authToken = this.getAuthToken();
        return this.http.get(`${this.baseUrl}admin/status/${id}`, { headers: { Authorization: authToken } }).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    /**Get Study Status */
    checkPriority(priority) {
        var authToken = this.getAuthToken();
        return this.http.get(`${this.baseUrl}admin/check/priority/${priority}`, { headers: { Authorization: authToken } }).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    /** List Study Status */
    studyStatusListApi(Dieases) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.post(`${this.baseUrl}admin/list/status`, Dieases).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    /** Line of Treatment Apis */
    /** Line of Treatment List */
    LineOfTreatmentListApi(treatment) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.post(`${this.baseUrl}admin/list/treatment`, treatment).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    /** Line Of Treatment status update */
    LineOfTreatmentStatusupdate(id, status) {
        let data = {
            iItemId: id,
            bStatus: status
        };
        var authToken = this.getAuthToken();
        return this.http
            .post(`${this.baseUrl}admin/treatment/changestatus`, data, { headers: { Authorization: authToken } })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /** Delete Line Of Treatment */
    deleteLineOfTreatmentTrialTool(id) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.delete(`${this.baseUrl}admin/treatment/${id}`).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    /** Treatment Add*/
    addTreatmentTrialTool(treatment) {
        var authToken = this.getAuthToken();
        return this.http
            .post(`${this.baseUrl}admin/treatment`, { sTitle: treatment }, { headers: { Authorization: authToken } })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /** Treament update */
    updateTreatmentTrialTool(id, treatment) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http
                .put(
                    `${this.baseUrl}admin/treatment/${id}`,
                    { sTitle: treatment },
                    { headers: { Authorization: authToken } }
                )
                .pipe(
                    map((response: any) => {
                        return response;
                    })
                );
        }
    }

    /**Get Treatment */
    getTreatmentTrialTool(id) {
        var authToken = this.getAuthToken();
        return this.http.get(`${this.baseUrl}admin/treatment/${id}`, { headers: { Authorization: authToken } }).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    /** Hospital Apis */
    /** List Hospital */
    hospitalListApi(hospital) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.post(`${this.baseUrl}admin/list/hospital`, hospital).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    /** Hospital Add*/
    addHospitalTrialTool(hospital) {
        var authToken = this.getAuthToken();
        return this.http
            .post(`${this.baseUrl}admin/hospital`, hospital, { headers: { Authorization: authToken } })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /** Disease update */
    updateHospitalTrialTool(id, hospital) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http
                .put(
                    `${this.baseUrl}admin/hospital/${id}`,
                    hospital,
                    { headers: { Authorization: authToken } }
                )
                .pipe(
                    map((response: any) => {
                        return response;
                    })
                );
        }
    }


    /** Delete Hospital */
    deleteHospitalTrialTool(id) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.delete(`${this.baseUrl}admin/hospital/${id}`).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    /** Disease status update */
    treatmentStatusupdate(id, status) {
        let data = {
            iItemId: id,
            bStatus: status
        };
        var authToken = this.getAuthToken();
        return this.http
            .post(`${this.baseUrl}admin/hospital/changestatus`, data, { headers: { Authorization: authToken } })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    getCityList(id) {
        var authToken = this.getAuthToken();
        return this.http.get(`${this.baseUrl}admin/disease/${id}`, { headers: { Authorization: authToken } }).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    /** Criteria Apis */
    // list api
    CriteriaListApi(criteria) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.post(`${this.baseUrl}admin/list/criteria`, criteria).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    /** Criteria Add*/
    addCriteriaTrialTool(criteria) {
        var authToken = this.getAuthToken();
        return this.http
            .post(`${this.baseUrl}admin/criteria`, criteria, { headers: { Authorization: authToken } })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /** Criteria update */
    updateCriteriaTrialTool(criteria) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http
                .put(
                    `${this.baseUrl}admin/criteria`,
                    criteria,
                    { headers: { Authorization: authToken } }
                )
                .pipe(
                    map((response: any) => {
                        return response;
                    })
                );
        }
    }

    /** Delete Principal Investigator */
    deleteCriteriaTrialTool(id) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.delete(`${this.baseUrl}admin/criteria/${id}`).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    /** Delete Criteria Item */
    deleteCriteriaItemApi(itemId) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.delete(`${this.baseUrl}admin/criteria/content/${itemId}`).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }


    /** Principal Investigator Apis */
    /** List Principal Investigator */
    investigatorListApi(Dieases) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.post(`${this.baseUrl}admin/list/investigator`, Dieases).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    /** Principal Investigator status update */
    investigatorStatusupdate(id, status) {
        let data = {
            iItemId: id,
            bStatus: status
        };
        var authToken = this.getAuthToken();
        return this.http
            .post(`${this.baseUrl}admin/investigator/changestatus`, data, { headers: { Authorization: authToken } })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /** Delete Principal Investigator */
    deleteInvestigatorTrialTool(id) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.delete(`${this.baseUrl}admin/investigator/${id}`).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    /** Investigator Add*/
    addInvestigatorTrialTool(investigator) {
        var authToken = this.getAuthToken();
        return this.http
            .post(`${this.baseUrl}admin/investigator`, investigator, { headers: { Authorization: authToken } })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /** Investigator update */
    updateInvestigatorTrialTool(id, investigator) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http
                .put(
                    `${this.baseUrl}admin/investigator/${id}`,
                    investigator,
                    { headers: { Authorization: authToken } }
                )
                .pipe(
                    map((response: any) => {
                        return response;
                    })
                );
        }
    }

    /** Global Page Api Call */
    getContentForUser(id) {
        return this.http.get(`${this.baseUrl}user/category/selectedcontent/${id}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    /** Studies APIS */
    /** Studies List API */
    StudiesListApi(Studies) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.post(`${this.baseUrl}admin/list/study`, Studies).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    /** Disease List API for Dropdown */
    getDiseaseList() {
        var authToken = this.getAuthToken();
        return this.http.get(`${this.baseUrl}admin/simpleList/disease`, { headers: { Authorization: authToken } }).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    /** Study Status List API for Dropdown */
    getStudyStatusList() {
        var authToken = this.getAuthToken();
        return this.http.get(`${this.baseUrl}admin/status`, { headers: { Authorization: authToken } }).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    /** Line Of Treatment List API for Dropdown */
    getTreatmentList() {
        var authToken = this.getAuthToken();
        return this.http.get(`${this.baseUrl}admin/treatment`, { headers: { Authorization: authToken } }).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    /** Line Of Treatment List API for Dropdown */
    getHospitalList() {
        var authToken = this.getAuthToken();
        return this.http.get(`${this.baseUrl}admin/simpleList/hospital`, { headers: { Authorization: authToken } }).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    // line of treatment list api for investigator panel for dropdown
    getHospitalListinvestigator() {
        var authToken = this.getAuthToken();
        return this.http.get(`${this.baseUrl}investigator/simpleList/hospital`, { headers: { Authorization: authToken } }).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    /** Line Of Treatment List API for Dropdown */
    getInvestigatorList() {
        var authToken = this.getAuthToken();
        return this.http.get(`${this.baseUrl}admin/simpleList/investigator`, { headers: { Authorization: authToken } }).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    /** Add Studies*/
    addStudies(studies) {
        var authToken = this.getAuthToken();
        return this.http
            .post(`${this.baseUrl}admin/study`, studies, { headers: { Authorization: authToken } })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /** Delete Country */
    deleteStudies(id) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.delete(`${this.baseUrl}admin/study/${id}`).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    /** Country status update */
    studiesStatusupdate(id, status) {
        let data = {
            iItemId: id,
            bStatus: status
        };
        var authToken = this.getAuthToken();
        return this.http
            .post(`${this.baseUrl}admin/study/changestatus`, data, { headers: { Authorization: authToken } })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /** Get Studies Details By Id*/
    getStudiesById(id: String) {
        const authToken = this.getAuthToken();
        if (authToken !== '') {
            return this.http.get(`${this.baseUrl}admin/study/${id}`).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    /** Studies Update  */
    updateStudies(id, data) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http
                .put(`${this.baseUrl}admin/study/${id}`, data, { headers: { Authorization: authToken } })
                .pipe(
                    map((response: any) => {
                        return response;
                    })
                );
        }
    }

    /** Get Investogators form Hospital*/
    getHospitalInvestigator(id) {
        var authToken = this.getAuthToken();
        return this.http
            .post(`${this.baseUrl}admin/hospitalinvestigator`, { iHospitalId: id }, { headers: { Authorization: authToken } })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    /** PATIENTS APIS CALL */
    /** PATIENTS List */
    patientsList(patients) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.post(`${this.baseUrl}admin/list/patient`, patients).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }
    patientsListinvestigator(patients) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.post(`${this.baseUrl}investigator/patient/list`, patients).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    /* Patients View  */
    viewPatients(id) {
        if (this.getAuthToken()) {
            return this.http
                .get(`${this.baseUrl}admin/patient/${id}`, { headers: { Authorization: this.getAuthToken() } })
                .pipe(
                    map((response: any) => {
                        return response;
                    })
                );
        }
    }
    //  investigator view patient
    investigatorviewPatients(id) {
        if (this.getAuthToken()) {
            return this.http
                .get(`${this.baseUrl}investigator/patient/${id}`, { headers: { Authorization: this.getAuthToken() } })
                .pipe(
                    map((response: any) => {
                        return response;
                    })
                );
        }
    }

    /** Delete Patient */
    deletePatient(id) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.delete(`${this.baseUrl}admin/patient/${id}`).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    deletePatientinvestigator(id) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.delete(`${this.baseUrl}investigator/patient/${id}`).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    /** Studies List API for Dropdown */
    getStudiesList() {
        var authToken = this.getAuthToken();
        return this.http.get(`${this.baseUrl}admin/list/studysimplelist`, { headers: { Authorization: authToken } }).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    // Studies List API for Dropdown  for investigator panel
    getStudiesListinvestigator() {
        var authToken = this.getAuthToken();
        return this.http.get(`${this.baseUrl}investigator/list/studysimplelist`, { headers: { Authorization: authToken } }).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    /** Criteria List API for Dropdown */
    getCriteriaList() {
        var authToken = this.getAuthToken();
        return this.http.get(`${this.baseUrl}admin/list/criteria/dropdown`, { headers: { Authorization: authToken } }).pipe(
            map((response: any) => {
                return response;
            })
        );
    }


    /** get Study List with search API for Dropdown */
    getStudyWithSearchList(search) {
        var authToken = this.getAuthToken();
        return this.http.post(`${this.baseUrl}admin/list/criteria/studysimplelist`, { search: search }, { headers: { Authorization: authToken } }).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    /** get Study List with search API & disease and line of treatmentfor Dropdown */
    getStudyWithDiseaseTreatmentList(iDiseaseId, iTreatmentId, search) {
        let paramters = {
            iDiseaseId: iDiseaseId,
            iTreatmentId: iTreatmentId,
            search: search
        }

        var authToken = this.getAuthToken();
        return this.http.post(`${this.baseUrl}admin/list/criteria/studyList`, paramters, { headers: { Authorization: authToken } }).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    /** Push Notification */
    pushNotification(data) {
        var authToken = this.getAuthToken();
        if (authToken != '') {
            return this.http.post(`${this.baseUrl}admin/push_message`, data).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
    }

    /* get permission data */
    viewPermissions(token) {
        if (this.getAuthToken()) {
            return this.http
                .get(`${this.baseUrl}admin/permissions/${token}`, { headers: { Authorization: this.getAuthToken() } })
                .pipe(
                    map((response: any) => {
                        return response;
                    })
                );
        }
    }

    sortCategory(data) {
        return this.http.post(`${this.baseUrl}admin/setOrder/category`, data).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
}
