import { Component, ViewEncapsulation, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { fuseAnimations } from '@fuse/animations';
import { SnackBarService } from 'app/main/common/snackbar.service';
import { NgModel } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AdminService } from 'app/main/common/adminService';

@Component({
    selector: 'fuse-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class FuseConfirmDialogComponent implements AfterViewInit {
    public confirmMessage: string;
    public permissionMessage: string;
    public content: any;
    public sponserView: any;
    public note: any;
    public advice: any;
    public criteriaDetails: any;
    public adviceText: any;
    public criteriaText: any;
    public index: any;
    public pasteText: any;
    public pastedText: any;
    public dialogOpenFrom: any;
    adviceForm: any;
    questions: any;
    criteriaQuestions: any;
    setAsFirst: any;
    setAsFirstNote: any;
    editQuestions: any;
    isEdit: any;
    criteriaIsEdit: any;
    studiesList = [];
    criteriaList: any;
    searchData: any;
    studiesListWithId: any;
    selectAllValueFilter = []
    selectedCountryData: any = [];
    search: any;
    criteriaIds: any;
    @ViewChild('toSearch') toSearch: ElementRef;

    constructor(
        public dialogRef: MatDialogRef<FuseConfirmDialogComponent>,
        private snackBarService: SnackBarService,
        private adminService: AdminService
    ) { }

    ngOnInit() {
        // let paramters = {
        //     iDiseaseId: this.criteriaIds.iDiseaseId,
        //     iTreatmentId: this.criteriaIds.iTreatmentId,
        //     search: ''
        // }

        this.search = ''
        /** get study with searching */
        // let categories = this.adminService.getStudyWithSearchList(this.search).toPromise();
        if (this.criteriaDetails) {
            let categories = this.adminService.getStudyWithDiseaseTreatmentList(this.criteriaIds.iDiseaseId, this.criteriaIds.iTreatmentId, this.search).toPromise();
            categories.then((response) => {
                this.studiesList = response.data;
            }, error => {
                this.snackBarService.snackbarMesssage(error.error['message'], 'Cancel');
            });
        }

    }

    /** used in trial-tool criteria section */
    ngAfterViewInit() {
        if (this.criteriaDetails && this.toSearch ) {
            console.log(this.toSearch,this.toSearch.nativeElement);
            
            const typeahead = fromEvent(this.toSearch.nativeElement, 'keyup').pipe(
                map((e: any) => e.target.value),
                debounceTime(1000),
                distinctUntilChanged()
            );
            typeahead.subscribe((event) => {
                this.searchStudy(event)
            });
        }
    }

    /** For Guideline Section */
    addDetails(adviceText) {
        if (
            ((adviceText.iLink) && (!adviceText.sAdviceLink) && (!adviceText.sAdvice) && (!adviceText.sAdviceTitle))
            || ((!adviceText.iLink) && (adviceText.sAdviceTitle) && (adviceText.sAdvice) && (adviceText.sAdviceLink))
            || ((!adviceText.iLink) && (adviceText.sAdviceTitle) && (adviceText.sAdvice) && (!adviceText.sAdviceLink))
        ) {
            let matchedQus;
            if (adviceText.iLink) {
                matchedQus = this.questions.filter(x => adviceText.iLink === x._id);
                adviceText.sLinkTitle = matchedQus[0].sTitle;
            }
            this.dialogRef.close(adviceText)
        } else {
            this.snackBarService.snackbarMesssage("Please select one option. Advice or Link Question", 'Cancel');
        }

        if (
            ((!adviceText.iLink) && (!adviceText.sAdviceTitle) && (adviceText.sAdvice) && (adviceText.sAdviceLink))
            || ((!adviceText.iLink) && (!adviceText.sAdviceTitle) && (adviceText.sAdvice) && (!adviceText.sAdviceLink))
        ) {
            this.snackBarService.snackbarMesssage("Advice Title is required", 'Cancel');
        }

        if (
            ((!adviceText.iLink) && (adviceText.sAdviceTitle) && (!adviceText.sAdvice) && (!adviceText.sAdviceLink))
            || ((!adviceText.iLink) && (adviceText.sAdviceTitle) && (!adviceText.sAdvice) && (adviceText.sAdviceLink))
        ) {
            this.snackBarService.snackbarMesssage("Advice Description is required", 'Cancel');
        }

        if ((!adviceText.iLink) && (!adviceText.sAdviceTitle) && (!adviceText.sAdvice) && (adviceText.sAdviceLink)) {
            this.snackBarService.snackbarMesssage("Advice title and advice description is required", 'Cancel');
        }
    }

    /** For Trial-Tool Criteria */
    addCriteriaDetails(criteriaText) {
        if ((!criteriaText.iNextQuestionLink && (!criteriaText.aStudies || criteriaText.aStudies.length === 0))) {
            this.snackBarService.snackbarMesssage("Please select one option. Criteria or Study", 'Cancel');
        } else {
            if (
                ((criteriaText.iNextQuestionLink) && (!criteriaText.aStudies || criteriaText.aStudies.length === 0))
                || ((!criteriaText.iNextQuestionLink) && (criteriaText.aStudies || criteriaText.aStudies >= 0))
            ) {
                if (!criteriaText.aStudies) {
                    criteriaText.aStudies = []
                }
                if (criteriaText.aStudies) {
                    var filtered = criteriaText.aStudies.filter(function (el) {
                        return el != "";
                        // return el != null;
                    });
                    criteriaText.aStudies = filtered;
                }
                this.dialogRef.close(criteriaText)
            } else {
                this.snackBarService.snackbarMesssage("Please select one option. Criteria or Study", 'Cancel');
            }
        }
    }

    /** start Select All Functionality */
    equals(objOne, objTwo) {
        if (typeof objOne !== 'undefined' && typeof objTwo !== 'undefined') {
            return objOne === objTwo;
        }
    }

    selectAll(checkAll, select: NgModel, values) {
        values.filter(x => {
            this.selectAllValueFilter.push(x._id)
        });
        //this.toCheck = !this.toCheck;
        if (checkAll) {
            // select.update.emit(values);
            select.update.emit(this.selectAllValueFilter);
        }
        else {
            select.update.emit([]);
        }
    }
    /** end Select All Functionality */

    /** Search Country in Country dropdown Api Call */
    searchStudy(search) {
        let categories = this.adminService.getStudyWithSearchList(search).toPromise();
        categories.then((response) => {
            // this.snackBarService.snackbarMesssage(response['message'], 'Cancel');
            this.studiesList = response.data;
        }, error => {
            this.snackBarService.snackbarMesssage(error.error['message'], 'Cancel');
        });
    }

    addText(pastedText, from) {
        if (pastedText) {
            let data = this.splitMe(pastedText);
            let response = {
                data: data,
                from: from
            }
            this.dialogRef.close(response)
        }
    }

    splitMe(pastedText) {
        let tempArr = []
        pastedText.split('\n').forEach(arr => {
            tempArr.push(arr.replace(/[0-9]./g, '').trimStart(' '));
        });
        return tempArr;
    }
}
