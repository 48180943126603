import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './main/login/login.component';
import { RoutingGuard } from './main/common/routing.guard';
import { ForgotPasswordComponent } from './main/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './main/reset-password/reset-password.component';
import { ViewAccordionComponent } from './main/view-accordion/view-accordion.component';
import { ViewTextComponent } from './main/view-text/view-text.component';
import { ProfileComponent } from './main/admin/profile/profile.component';
import { ChangePasswordComponent } from './main/admin/change-password/change-password.component';
import { MailConfirmComponent } from './main/mail-confirm/mail-confirm.component';
import { Error404Component } from './main/errors/404/error-404.component';
import { OtpVerifyComponent } from './main/otp-verify/otp-verify.component';
import { AddInvestigatorbylinkComponent } from './main/admin/add-investigatorbylink/add-investigatorbylink.component';
import { SearchComponent } from './main/admin/search/search.component';

import { from } from 'rxjs';
const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        canActivate: [RoutingGuard],
        component: LoginComponent
    },
    {
        path: 'login',
        pathMatch: 'full',
        canActivate: [RoutingGuard],
        component: LoginComponent
    },
    {
        path: 'investigator',
        component: LoginComponent
    },
    {
        path: 'investigator/:id',
        component: AddInvestigatorbylinkComponent
    },
    {
        path: 'profile',
        component: ProfileComponent
    },
    {
        path: 'change-password',
        component: ChangePasswordComponent
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent
    },
    {
        path: 'otp-verification',
        component: OtpVerifyComponent
    },
    {
        path: 'reset/:token',
        data: {
            title: 'Reset Password'
        },
        component: ResetPasswordComponent
    },
    {
        path: 'user/mail/verification/:tokenData',
        component: MailConfirmComponent,
    },
    {
        path: 'mail-confirm',
        component: MailConfirmComponent,
    },
    {
        path: '',
        canActivate: [RoutingGuard],
        children: [
            {
                path: 'dashboard',
                loadChildren: './main/admin/Dashboard/dashboard.module#DashboardModule'
            },
            {
                path: 'sub-admin',
                loadChildren: './main/admin/sub-admin/sub-admin.module#SubAdminModule'
            },
            {
                path: 'sponsor',
                loadChildren: './main/admin/sponser/sponser-management.module#SponserManagementModule'
            },
            {
                path: 'category',
                loadChildren: './main/admin/categories/categories.module#CategoriesModule'
            },
            {
                path: 'patient',
                loadChildren: './main/admin/patients/patients.module#PatientsModule'
            },
            {
                path: 'patients',
                // import {investigatorPatientsModule} from './main/investigator/patients/patients.module'
// 
                // component:ChangePasswordComponent
                loadChildren: './main/investigator/patients/patients.module#investigatorPatientsModule'
            },
            {
                path: 'cms',
                loadChildren: './main/admin/cms/cms.module#CmsModule'
            },
            {
                path: 'trial-tools',
                loadChildren: './main/admin/trial-tools/trial_tool.module#TrialToolsModule'
            },
            {
                path: 'notification',
                loadChildren: './main/admin/notification/notification.module#NotificationModule'
            },
        ]
    },
    {
        path: 'accordion/view/:id',
        component: ViewAccordionComponent
    },
    {
        path: 'text/view/:id',
        component: ViewTextComponent
    },
    {
        path: 'pdf/view/:id',
        component: Error404Component
    },
    {
        path: 'calculator/view/:id',
        component: Error404Component
    },
    {
        path: 'guideline/view/:id',
        component: Error404Component
    },
    {
        path: 'graphical/view/:id',
        component: Error404Component
    },
    {
        path: 'search-criteria',
        component: SearchComponent
    },
    {
        path: '**',
        redirectTo: 'errors/error-404',
    }

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
