import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { mergeMap } from 'rxjs/operators';
import { SnackBarService } from './snackbar.service';
import { AdminService } from './adminService';

@Injectable()
export class InterceptService implements HttpInterceptor {
	constructor(public router: Router,
		private snackbarService: SnackBarService, private adminService: AdminService) { }
	/* intercept request and add token */
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return of(null).pipe(
			mergeMap(() => {
				if (
					request.url.endsWith('/admin/login') ||
					request.url.endsWith('/investigator/login') ||
					request.url.endsWith('/admin/forgot/password/mail') ||
					request.url.endsWith('/auth/register') ||
					request.url.endsWith('/admin/reset/password') ||
					request.url.endsWith('/investigator/register') ||
					request.url.endsWith('/investigator/list/countries/dropdown') ||
					request.url.includes('/investigator/city/list/dropdown') ||
					request.url.includes('/investigator/list/hospital') ||
					request.url.includes('/investigator/study') ||
					request.url.includes('/admin/verifytoken') || //add dynemic token in header user include
					request.url.includes('/user/category/selectedcontent') || //add dynemic id in header user include
					request.url.includes('/admin/subadmin/mail/verification') || //add dynemic token in header user include
					request.url.endsWith('.json')
				) {
					return next.handle(request).pipe(
						tap(
							(event) => {
								if (event instanceof HttpResponse) {
								}
							},
							(error) => {
								// console.log('***1***', error);
								/* http response status code */
								if (error.status == 401) {
									this.router.navigate(['/']);
									let message = error.error.message;
									this.snackbarService.snackbarMesssage(message, 'Cancel');
								}
								// else {
								// 	let message = error.error.message;
								// 	this.snackbarService.snackbarMesssage(message, 'Cancel');
								// }
								if (error.status === 0) {
									this.snackbarService.snackbarMesssage('Something wrong at server side', 'Cancel');
								}
							}
						)
					);
				} else {
					var storageValues = (!this.adminService.storageValues) ? JSON.parse(localStorage.getItem('currentUser')) : this.adminService.storageValues;
					request = request.clone({
						setHeaders: {
							Authorization: storageValues.Authorization
						}
					});
					return next.handle(request).pipe(
						tap(
							(event) => {
								if (event instanceof HttpResponse) {
									/* http response status code */
								}
							},
							(error) => {
								// console.log('***2***', error);
								if (error.status == 401) {
									let message = error.error.message;
									this.snackbarService.snackbarMesssage(message, 'Cancel');
									this.router.navigate(['/']);
									localStorage.removeItem('currentUser');
								}
								if (error.status === 0) {
									this.snackbarService.snackbarMesssage('Something wrong at server side', 'Cancel');
								}
								//  else {
								// 	let message = error.message;
								// 	this.snackbarService.snackbarMesssage(message, 'Cancel');
								// }
								/* http response status code */
							}
						)
					);
				}
			})
		);
	}
}
