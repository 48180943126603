import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule, MatIconModule, MatSnackBar, MatDialogModule, MatFormFieldModule, MatInputModule, MatProgressSpinnerModule, MatSelectModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { fuseConfig } from 'app/fuse-config';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { LoginModule } from './main/login/login.module';
import { CookieService } from 'ngx-cookie-service';
import { InterceptService } from './main/common/intercepter.service';
import { AdminService } from './main/common/adminService';
import { RoutingGuard } from './main/common/routing.guard'
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SnackBarService } from './main/common/snackbar.service';
import { PermissionService } from './main/common/permissions';
import { PermissionAccessService } from './main/common/permission_access.service';
import { SafePipe } from '@fuse/pipes/safeContent.pipe';
import { ProfileModule } from './main/admin/profile/profile.module';
import { ChangePasswordModule } from './main/admin/change-password/change-password.module';
import { ResetPasswordModule } from './main/reset-password/reset-password.module';
import { ForgotPasswordModule } from './main/forgot-password/forgot-password.module';
import { MailConfirmModule } from './main/mail-confirm/mail-confirm.module';
import { Error404Module } from './main/errors/404/error-404.module';
import { Error500Module } from './main/errors/500/error-500.module';
import { CommonModule } from '@angular/common';
import { ViewAccordionModule } from './main/view-accordion/view-accordion.module';
import { ViewTextModule } from './main/view-text/view-text.module';
import {SearchModule } from './main/admin/search/search.module'

import * as firebase from 'firebase';
import { AddInvestigatorbylinkComponent } from './main/admin/add-investigatorbylink/add-investigatorbylink.component';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTreeModule } from '@angular/material/tree';



// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyCte1kHtXqnTkNapcKRzRFiU2VVcnJl3K8",
    authDomain: "hematology-api.firebaseapp.com",
    databaseURL: "https://hematology-api.firebaseio.com",
    projectId: "hematology-api",
    storageBucket: "hematology-api.appspot.com",
    messagingSenderId: "245395691620",
    appId: "1:245395691620:web:dfec5279083fad1c822771",
    measurementId: "G-9TL0S4VN6Q"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

@NgModule({
    declarations: [
        AppComponent,
        AddInvestigatorbylinkComponent

    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        TranslateModule.forRoot(),
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        MatSelectModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // Mat-Modules
        MatMomentDateModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressSpinnerModule,

        // App modules
        LoginModule,
        LayoutModule,
        ProfileModule,
        ChangePasswordModule,
        ResetPasswordModule,
        ForgotPasswordModule,
        MailConfirmModule,
        Error404Module,
        Error500Module,
        ViewAccordionModule,
        ViewTextModule,
        MailConfirmModule,
        DragDropModule,
        MatTreeModule,
        SearchModule

    ],
    providers: [
        SnackBarService,
        PermissionService,
        PermissionAccessService,
        MatSnackBar,
        AdminService,
        RoutingGuard,
        CookieService,
        SafePipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptService,
            multi: true
        },
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
