import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import * as firebase from 'firebase';
import { AdminService } from '../common/adminService';
import { Router } from '@angular/router';
import { PermissionService } from '../common/permissions';
import { SnackBarService } from '../common/snackbar.service';
@Component({

    selector: 'verify',
    templateUrl: './otp-verify.component.html',
    styleUrls: ['./otp-verify.component.scss']
})
export class OtpVerifyComponent implements OnInit {
    displayMenuItem: [];
    name: any;
    confirmationResult: firebase.auth.ConfirmationResult;
    sessionData: { Authorization: any; };

    constructor(

        public matDialogRef: MatDialogRef<OtpVerifyComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        public adminService: AdminService,
        public router: Router,
        public snackBarService: SnackBarService,
        public permissionService: PermissionService
    ) {

    }

    ngOnInit(): void {
        this.confirmationResult = this._data.confirmresult;
    }

    verifydata(): void {
        console.log(this.name);
        this.confirmationResult.confirm(this.name).then(user => {
            console.log(user);
            this.matDialogRef.close({ success: true });
            // this.router.navigate(['/dashboard']);
        }, err => {
            console.log(err);
            this.snackBarService.snackbarMesssage(err.message, 'Cancel');

        });
    }
}
